<template>
  <div class="hello">
      <div id='container'>
        <div  id='DmanTip' class="position-item"  >
          <div id='date'>
            Fecha:26.03.2021 09:58:31 UTC
          </div>
          <div id='tip'>
            SYNC - ordinary call that's good still.
          </div>
      </div>
          <div id='date'>
            Fecha:  12.03.2021 17:57:04 UTC
          </div>
          <div id='tip'>
            SYNC - has very serious development team, could do well.
          </div>
      </div>
          <div id='date'>
            Fecha:  08.03.2021 13:39:56 UTC
          </div>
          <div id='tip'>
            sync target is 5x from my announced at 0.1159, now it's 0.169 - this is not bad, not too bad, but be aware of it. Now the initial wave has passed, wait for a bit of retracement before buying. So always have 1) target 2) my confidence (ordinary signal) in mind when judging whether to buy in or not.

          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SYNC',
  data () {
    return {
    }
  },
  methods: {
  },
}

</script>
